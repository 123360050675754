<template>
  <div
    class="home"
    :style="!isNoXCX ? 'padding-top: 0;' : ''"
  >
    <template v-if="isNoXCX">
      <div class="all-title">转赠</div>
      <div
        class="common-back"
        @click="backAction"
      >
        <img
          src="https://artimg2.artart.cn/image/20220428/c2e0865b1ace1cb7c56e7427381c234e.png"
          alt=""
        >
      </div>
    </template>
    <div class="list">
      <div
        class="item"
        v-if="detail"
      >
        <div class="item-top flex">
          <div class="img">
            <van-image
              style="width: 100%;height: 100%;"
              :src="detail.product.image + (detail.product.image.toLowerCase().indexOf('.gif') === -1 ? '!m640' : '')"
              fit="contain"
            ></van-image>
          </div>
          <div class="item-t-right">
            <div class="name">{{ detail.product.store_name }}</div>
            <div class="time">{{ detail.create_time }}</div>
            <!-- <div class="send-status">当前藏品可赠送</div> -->
            <!-- <div class="send-status no">购买未满180天不可赠送</div> -->
          </div>
        </div>
        <div class="item-bottom flex align-items-c">
          <img
            src="https://artimg2.artart.cn/image/20220428/a56d438c09bbe654f776e492c4900838.png"
            alt=""
          >
          <div class="sdk line1">{{ detail.tx_hash }}</div>
          <!-- <div class="btn">查看详情</div> -->
        </div>
      </div>
      <div class="send-phone">
        <input
          v-model="recipient"
          placeholder="接收人区块链地址"
        >
        <div
          class="btn"
          @click="scanAction"
        >扫码识别</div>
      </div>
      <div
        class="send-btn"
        @click="showSendGiftAction"
      >转赠</div>
      <!-- <div class="txt">数字藏品的无偿转赠需要满足一定的条件。用户购买拥有数字藏品达到180天，可以向其他用户发起转赠，同时受赠方应该符合数字藏品的购买条件(年满14周岁的中国大陆居民)，并通过账户的实名认证。为防止炒作，受赠方接收数字藏品满2年后，可以再次发起转赠。</div> -->
      <div class="txt">数字藏品的无偿转赠需要满足一定的条件。用户购买后可以向其他用户发起转赠，同时受赠方应该符合数字藏品的购买条件(年满14周岁的中国大陆居民)，并通过账户的实名认证。</div>
    </div>
    <div
      class="change-pay-type item-flex-center"
      v-if="showSendType"
      @click="showSendType = false;"
    >
      <div
        class="pay-type-content"
        @click.stop="kong"
      >
        <div class="pay-type-title">转赠<img
            class="close"
            src="https://artimg2.artart.cn/image/20220501/a9f6a5d91c404291dcfa529524148910.png"
            @click="showSendType = false;"
          ></div>
        <div class="pay-type-c">
          <div class="item line1">作品名：{{ detail.product.store_name }}</div>
          <div class="item line1">转赠给：{{ send_user }}</div>
        </div>
        <div class="pay-type-btn">
          <div
            class="btn"
            @click="sendGiftAction"
          >确认转赠</div>
        </div>
      </div>
    </div>
    <Footer />
  </div>
</template>

<script>
import { ref, onBeforeMount, computed } from "vue";
import { Image as VanImage, Toast } from "vant";
import { ownNftInfoApi, transfersApi, findAccountUserApi } from "@/api/nft";
import { useRoute, useRouter } from "vue-router";
import { useStore } from "vuex";
import { returnWxPayType } from "@/utils/util";

export default {
  components: {
    [VanImage.name]: VanImage,
  },
  setup() {
    const route = useRoute();
    const router = useRouter();
    const store = useStore();
    const showSendType = ref(false);

    const id = ref("");
    const detail = ref("");
    const recipient = ref("");

    onBeforeMount(() => {
      id.value = route.query.id;
      ownNftInfoApi(id.value)
        .then((res) => {
          detail.value = res.data;
        })
        .catch((message) => Toast(message));
    });

    let payLock = false;

    const send_user = ref("");

    const showSendGiftAction = () => {
      if (!recipient.value) {
        Toast("请输入接收人区块链地址或者扫码识别");
        return false;
      }
      send_user.value = "";
      showSendType.value = true;
      findAccountUserApi({ account: recipient.value })
        .then((res) => {
          send_user.value = res.data.user_info.nickname;
        })
        .catch((message) => {
          showSendType.value = false;
          Toast(message);
        });
    };
    const backAction = () => {
      if (window.history.length <= 1) {
        router.replace("/");
      } else {
        router.back();
      }
    };
    const sendGiftAction = () => {
      if (payLock) return false;
      payLock = true;
      Toast.loading({
        duration: 0,
        forbidClick: true,
      });
      const data = {
        recipient: recipient.value,
        nft_data_id: id.value,
      };
      transfersApi(data)
        .then((res) => {
          Toast.clear();
          payLock = false;
          showSendType.value = false;
          backAction();
          Toast(res.message);
        })
        .catch((message) => {
          Toast.clear();
          payLock = false;
          showSendType.value = false;
          Toast(message);
        });
    };

    const kong = () => {};

    const scanAction = () => {
      // eslint-disable-next-line no-undef
      wx.miniProgram.getEnv((res) => {
        if (res.miniprogram) {
          // eslint-disable-next-line no-undef
          wx.miniProgram.scanQRCode({
            scanType: ["qrCode", "barCode"], // 可以指定扫二维码还是一维码，默认二者都有
            success: (res) => {
              if (res.result) {
                try {
                  const jsonD = JSON.parse(res.result);
                  if (jsonD.own_account) {
                    recipient.value = jsonD.own_account;
                  } else {
                    Toast("该二维码错误");
                  }
                } catch (e) {
                  Toast("请扫描接收人数字账户二维码");
                }
              }
            },
          });
        } else {
          // eslint-disable-next-line no-undef
          wx.scanQRCode({
            needResult: 1, // 默认为0，扫描结果由微信处理，1则直接返回扫描结果，
            scanType: ["qrCode", "barCode"], // 可以指定扫二维码还是一维码，默认二者都有
            success: function (res) {
              if (res.resultStr) {
                try {
                  const jsonD = JSON.parse(res.resultStr);
                  if (jsonD.own_account) {
                    recipient.value = jsonD.own_account;
                  } else {
                    Toast("该二维码错误");
                  }
                } catch (e) {
                  Toast("请扫描接收人数字账户二维码");
                }
              }
            },
          });
        }
      });
    };
    return {
      detail,
      recipient,
      showSendType,
      send_user,
      isNoXCX: returnWxPayType() == "wechat_h5",

      kong,
      showSendGiftAction,
      sendGiftAction,
      backAction,
      scanAction,
    };
  },
};
</script>

<style lang="scss" scoped>
.home {
  background: #1e1e1e;
  padding-top: 80px;
  padding-bottom: 198px;
  max-height: 99999px;
}
.list {
  padding-top: 10px;

  .send-phone {
    height: 80px;
    border-radius: 40px;
    border: 2px solid #333333;
    margin: 32px 30px 0 30px;
    display: flex;

    input {
      background: none;
      outline: none;
      border: none;
      padding: 20px 38px;
      box-sizing: border-box;
      line-height: 40px;
      color: #fff;
      font-size: 28px;
      flex: 1;
    }

    .btn {
      padding: 0 20px;
      line-height: 80px;
      color: #00eeff;
      font-size: 24px;
    }
  }

  .send-btn {
    height: 96px;
    background: #00eeff;
    border-radius: 48px;
    text-align: center;
    margin: 32px 32px 0 32px;
    line-height: 96px;
    color: #000;
    font-size: 32px;
    font-weight: 500;
  }

  .txt {
    margin: 46px 32px 0 32px;
    width: 686px;
    font-size: 24px;
    font-weight: 400;
    color: #666666;
    line-height: 40px;
  }

  .item {
    width: 686px;
    height: 280px;
    background: #2d2d2d;
    border-radius: 16px;
    margin-left: 32px;
    overflow: hidden;
    margin-top: 32px;

    .item-top {
      height: 200px;
      border-radius: 16px;
      overflow: hidden;
      background: #333;

      .img {
        background: #999;
        width: 200px;
        height: 200px;
      }
    }
    .item-t-right {
      padding: 28px 32px;
      flex: 1;
      overflow: hidden;

      .name {
        height: 44px;
        line-height: 44px;
        font-size: 32px;
        font-weight: 500;
        color: #ffffff;
        text-overflow: -o-ellipsis-lastline;
        overflow: hidden;
        text-overflow: ellipsis;
        display: -webkit-box;
        -webkit-line-clamp: 1;
        line-clamp: 1;
        -webkit-box-orient: vertical;
      }

      .time {
        color: #999;
        font-size: 24px;
        margin-top: 16px;
      }

      .send-status {
        color: #43ff8b;
        margin-top: 16px;

        &.no {
          color: #ff4253;
        }
      }
    }

    .item-bottom {
      height: 80px;
      margin: 0 32px;
      img {
        width: 32px;
        height: 32px;
        margin-right: 8px;
      }
      .sdk {
        flex: 1;
        font-size: 24px;
        font-weight: 500;
        color: #e6cb95;
        margin-right: 20px;
      }
      .btn {
        font-size: 24px;
        font-weight: 400;
        color: #00eeff;
      }
    }
  }
}

.change-pay-type {
  position: fixed;
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
  z-index: 1000;
  background: rgba(0, 0, 0, 0.7);

  .pay-type-content {
    background: #2d2d2d;
    width: 560px;
    border-radius: 32px;
    padding-bottom: 60px;

    .pay-type-title {
      padding-top: 42px;
      text-align: center;
      font-size: 36px;
      font-weight: 500;
      color: #fff;
      position: relative;

      img {
        width: 48px;
        height: 50px;
        display: block;
        top: 42px;
        right: 40px;
        position: absolute;
      }
    }

    .pay-type-c {
      margin-top: 4px;

      .item {
        padding: 0 64px 0 64px;
        font-size: 28px;
        color: #fff;
        line-height: 48px;
        &:first-child {
          padding-top: 64px;
        }
      }
    }

    .pay-type-btn {
      margin-top: 74px;
      margin-left: 130px;

      .btn {
        width: 300px;
        height: 96px;
        background: #00eeff;
        border-radius: 48px;
        line-height: 96px;
        text-align: center;
        font-size: 32px;
        color: #000;
        font-weight: 500;
      }
    }
  }
}
</style>
